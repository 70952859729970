<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="estimatedTag">
    <EstimatedTagNew
      v-if="isPercentNewType"
      :config="config"
      :price="price"
      :language="language"
      :count-timer="countTimer"
      :countdown-tips="countdownTips"
      :estimated-info="estimatedInfo"
      :is-special-flash="isSpecialFlash"
      :is-show-from="isShowFrom"
      :is-show-vat="isShowVat"
      :is-belt-type="isBeltType"
      :is-belt-type-brand="isBeltTypeBrand"
      :is-quick-add-belt="isQuickAddBelt"
      :texts="texts"
      :container-style="containerStyle"
      :tax-included-text="taxIncludedText"
      :has-link="hasLink"
      @click-link="handleClick"
    />
    <EstimatedNoThresholdNew
      v-else-if="showEstimatedNoThresholdNewType"
      :price="price"
      :estimated-info="estimatedInfo"
      :language="language"
      :config="config"
      :count-timer="countTimer"
      :css-right="cssRight"
      :is-show-add="isShowAdd"
      @click-link="handleClick"
    />
    <EstimatedTagOld
      v-else-if="showEstimatedTagOld"
      :config="config"
      :price="price"
      :language="language"
      :count-timer="countTimer"
      :countdown-tips="countdownTips"
      :container-style="containerStyle"
      :is-belt-type="isBeltType"
      :is-belt-type-brand="isBeltTypeBrand"
      :is-quick-add-belt="isQuickAddBelt"
      :is-special-flash="isSpecialFlash"
      :texts="texts"
      :compliance-mode="estimatedInfo.complianceMode"
      :compliance-mode-de="estimatedInfo.complianceModeDe"
      :has-link="hasLink"
      :is-quick-add="isQuickAdd"
      @click-link="handleClick"
    />
  </div>
</template>
<script>
import { debounce } from '@shein/common-function'

if(typeof window !== 'undefined') {
  window._exposeEstimatedTagFn_ = debounce({
    func: (args) => {
      daEventCenter.triggerNotice(args)
    },
    wait: 2000
  })
}
</script>

<script name="EstimatedTag" setup>
import { template, CountDown, cloneDeep } from '@shein/common-function'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import EstimatedTagOld from './EstimatedTagOld'
import EstimatedTagNew from './EstimatedTagNew'
import EstimatedNoThresholdNew from './EstimatedNoThresholdNew'
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

let countTimer = ref({})
const percentDom = ref(null)
const retailDom = ref(null)
const vatDom = ref(null)
const cssRight = ref(!!gbCommonInfo?.GB_cssRight)

const ESTIMATED_TAG_STYLE_TYPE = {
  NORMAL: 'NORMAL',
  SPECIAL_FLASH: 'SPECIAL_FLASH',
}

const CONTAINER_STYLE_MAP = {
  [ESTIMATED_TAG_STYLE_TYPE.NORMAL]: {
    bg: '#FFECE9',
    color: '#FA6338',
  },
  [ESTIMATED_TAG_STYLE_TYPE.SPECIAL_FLASH]: {
    bg: '#FA6338',
    color: '#FFFFFF',
  }
}
const isPercentBreak = ref(false)
const isRetailBreak = ref(false)
const isVatBreak = ref(false)


const props = defineProps({
  analysisData: {
    type: Object,
    default: () => ({
      location: 'popup', // 商详 page 加车弹窗 popup
    })
  },
  language: {
    type: Object,
    default: () => ({})
  },
  goodsId: {
    type: String,
    default: ''
  },
  promotionInfo: {
    type: Array,
    default: () => []
  },
  estimatedInfo: {
    type: Object,
    default: () => ({})
  },
  isSpecialFlash: {
    type: Boolean,
    default: false
  },
  isShowFrom: {
    type: Boolean,
    default: false
  },
  isShowVat: {
    type: Boolean,
    default: false
  },
  isBeltType: {
    type: Boolean,
    default: false
  },
  isEstimatedNoThresholdNewType: {
    type: Boolean,
    default: false
  },
  taxIncludedText: {
    type: String,
    default: ''
  },
  isShowAdd: {
    type: Boolean,
    default: false
  },
  isBeltTypeBrand: {
    type: Boolean,
    default: false
  },
  isQuickAddBelt: {
    type: Boolean,
    default: false
  },
  isFloorType: {
    type: Boolean,
    default: false
  },
  hasLink: {
    type: Boolean,
    default: false
  },
  isQuickAdd: {
    type: Boolean,
    default: false
  },
})

const config = computed(() => {
  return props.estimatedInfo?.estimatedCompProps?.config || {}
})

const price = computed(() => {
  return props.estimatedInfo?.estimatedCompProps?.price || {}
})

const emit = defineEmits(['click-link'])

const texts = computed(() => {
  let langs = (props.language.SHEIN_KEY_PC_26183 || 'Estimated {0}')?.split?.('{0}')?.map?.(str => str.trim())
  return {
    estimatedBefore: langs[0],
    ifTotalOrderOver: template(price.value.addOnAmountWithSymbol, props.language.SHEIN_KEY_PC_26184 || 'with {0} + purchase'), 
    estimatedAfter: langs[1] || '',
  }
})

const countdownTips = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  let spanTxt = txt => `<span>${txt}</span>`
  return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
})

const isPercentNewType = computed(() => {
  return !!price.value.discountPercent && !props.isEstimatedNoThresholdNewType
})

const isUseNewType = computed(() => {
  return isPercentNewType.value
})

const showEstimatedNoThresholdNewType = computed(() => {
  return props.isEstimatedNoThresholdNewType && 
    ['New1', 'New2'].includes(config.value.EstimatedNothreShowType) &&
    !!price.value.addOnAmountWithSymbol &&
    !!price.value.needPrice
})

const showEstimatedTagOld = computed(() => {
  if (props.isEstimatedNoThresholdNewType) return false
  return !(['New1', 'New2'].includes(config.value.EstimatedNothreShowType) && !!price.value.addOnAmountWithSymbol && !props.isEstimatedNoThresholdNewType)
})

const containerStyle = computed(() => {
  if (props.isBeltType || props.isQuickAddBelt) return {
    color: '#FFF',
    bg: '#FA6338'
  }
  const res = cloneDeep(CONTAINER_STYLE_MAP[config.value.styleType])
  if (isUseNewType.value) {
    res.color = config.value.isPaidUser ? '#873C00' : '#FA6338'
    res.bg = config.value.isPaidUser ? '#873C00' : '#FA6338'
  }
  return res
})

const triggerExpose = () => {
  if (props.isFloorType) return 
  let threshold
  if (props.estimatedInfo.isAb) {
    threshold = 3
  } else if (props.estimatedInfo?.isSatisfiedBuyMultiple) {
    threshold = 6
  } else if (price.value.addOnAmountWithSymbol) {
    threshold = 2
  } else {
    threshold = 1
  }
  window._exposeEstimatedTagFn_({
    daId: '1-6-1-155',
    extraData: {
      ...props.analysisData,
      threshold, // 满足门槛上报为 1 ，未满足门槛上报为2
      estimated_price: price.value.usdAmount, // 到手价 amountWithSymbol
      timelimit: config.value.isShowCountdown ? 1 : 0,
      showtype: isPercentNewType.value ? 1 : 0,
      clubprice: props.estimatedInfo.isNewSheinClubShipping ? 1 : 0,
      ...(price.value.addOnAmountWithSymbol ? { nothroshowtype: showEstimatedNoThresholdNewType.value ? 1 : 0 } : {})
    }
  })
}

const initTimer = () => {
  if(!config.value.isShowCountdown) return
  // 倒计时开始时间
  countTimer.value = new CountDown()
  const beginTime = config.value.bestEndTime * 1000 - new Date().getTime()
  if (beginTime <= 0) {
    beginTime == 0
  }

  // 初始化倒计时
  countTimer.value?.start({
    seconds: beginTime / 1000,
    endFunc: debounce({
      func: () => {
        location.reload()
      }
    })
  })
}

const calPercentTop = () => {
  if(typeof document === 'undefined') return
  const offsetTop = percentDom.value?.offsetTop
  isPercentBreak.value = offsetTop > 10
}

const calRetailTop = () => {
  if(typeof document === 'undefined') return
  const offsetTop = retailDom.value?.offsetTop
  isRetailBreak.value = offsetTop > 10
}

const calVatTop = () => {
  if(typeof document === 'undefined') return
  const offsetTop = vatDom.value?.offsetTop
  isVatBreak.value = offsetTop > 10
}

onMounted(() => {
  cssRight.value = gbCommonInfo?.GB_cssRight
  triggerExpose()
  watch(() => props.goodsId, () => {
    nextTick(triggerExpose)
  })
  watch(() => props.promotionInfo, () => {
    initTimer()
  })
  watch(() => props.estimatedInfo, () => {
    initTimer()
  })
  initTimer()

  nextTick(calPercentTop)
  nextTick(calRetailTop)
  nextTick(calVatTop)
})

const handleClick = () => {
  if (!props.hasLink) return
  let threshold
  if (props.estimatedInfo.isAb) {
    threshold = 3
  } else if (props.estimatedInfo?.isSatisfiedBuyMultiple) {
    threshold = 6
  } else if (price.value.addOnAmountWithSymbol) {
    threshold = 2
  } else {
    threshold = 1
  }
  daEventCenter.triggerNotice({
    daId: '1-6-1-156',
    extraData: {
      ...props.analysisData,
      threshold,
      estimated_price: price.value.usdAmount,
      timelimit: config.value.isShowCountdown ? 1 : 0,
      showtype: isPercentNewType.value ? 1 : 0,
      ...(price.value.addOnAmountWithSymbol ? { nothroshowtype: showEstimatedNoThresholdNewType.value ? 1 : 0 } : {})
    }
  })
  emit('click-link')
}

</script>

<style lang="less">
.estimatedTag {
  display: flex;
  max-width: 100%;
}
</style>

<template>
  <div
    class="product-price-estimated"
    :style="{ 
      ...strategyStyle,
      cursor: hasLink ? 'pointer' : 'default'
    }"
    @click="handleClick"
  >
    <!-- 合规划线价到手价 -->
    <template v-if="complianceMode || complianceModeDe">
      <p class="product-price-estimated__font-14px price-area">
        {{ price.amountWithSymbol }}
      </p>
      <!-- 竖线 -->
      <div
        class=" product-price-estimated__vertical"
        :style="{
          background: strategyStyle.color}"
      ></div>
      <p class="product-price-estimated__font-14px product-price-estimated__text-overflow2">
        {{ complianceModeText }}
      </p>
    </template>
    <template v-else-if="!!price.buyMultiplePic">
      <p
        class="product-price-estimated__font-18px"
      >
        {{ buyMultipleText.left }}
      </p>
      <div
        class=" product-price-estimated__vertical"
        :style="{
          background: strategyStyle.color
        }"
      ></div>
      <p
        class="product-price-estimated__font-14px"
        v-html="buyMultipleText.right"
      >
      </p>
    </template>
    <template v-else-if="!price.addOnAmountWithSymbol">
      <Icon
        class="product-price-estimated__icon"
        name="sui_icon_coupon_discount_18px"
        size="18px"
      />
      <p class="product-price-estimated__price">
        <span class="product-price-estimated__font-14px">{{ texts.estimatedBefore }}</span>
        <span class="product-price-estimated__font-18px">&nbsp;{{ price.amountWithSymbol }}</span>
        <span class="product-price-estimated__font-14px">{{ texts.estimatedAfter }}</span>
      </p>
    </template>
    <template v-else>
      <p class="product-price-estimated__font-18px">
        {{ price.amountWithSymbol }}
      </p>
      <div
        class=" product-price-estimated__vertical"
        :style="{
          background: strategyStyle.color}"
      ></div>
      <p class="product-price-estimated__font-14px">
        {{ texts.ifTotalOrderOver }}
      </p>
    </template>
    <div 
      v-if="countTimer.timeObj && config.isShowCountdown && !complianceMode && !complianceModeDe"
      class="product-price-estimated__countdown-wrap"
    >
      {{ language.SHEIN_KEY_PC_26828 }} 
      <div
        class="product-price-estimated__countdown-time"
        v-html="countdownTips"
      ></div>
    </div>
    <Icon
      v-if="hasLink"
      class="product-price-estimated__icon-link"
      name="sui_icon_more_right_14px_1"
      size="14px"
      :is-rotate="GB_cssRight"
    />
  </div>
</template>

<script name="EstimatedTagOld" setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { computed } from 'vue'
import { template } from '@shein/common-function'
const { GB_cssRight } = typeof gbCommonInfo !== 'undefined' && gbCommonInfo || {}
const ESTIMATED_TAG_OLD_STYLE = {
  normal: {
    color: '#FA6338',
    background: '#FFF4F1'
  },
  darkRed: {
    color: '#FFF',
    background: '#FA6338'
  },
  whiteRed: {
    color: '#FA6338',
    background: '#FFF'
  },
  normalVip: {
    color: '#873C00',
    background: '#FFECE9'
  },
  normalFlash: {
    color: '#000',
    background: '#FFEFAB'
  },
  vipNormalFlash: {
    color: '#873C00',
    background: 'rgba(255, 255, 255, 0.87)'
  }
}

const props = defineProps({
  config: {
    type: Object,
    default: () => ({
      styleType: 'NORMAL',
      isPaidUser: false,
    })
  },
  price: {
    type: Object,
    default: () => ({
      // 到手价
      amountWithSymbol: '',
      // 门槛价
      addOnAmountWithSymbol: ''
    })
  },
  language: {
    type: Object,
    default: () => ({})
  },
  countTimer: {
    type: Object,
    default: () => ({})
  },
  countdownTips: {
    type: Object,
    default: () => ({})
  },
  // containerStyle: {
  //   type: Object,
  //   default: () => ({})
  // },
  texts: {
    type: Object,
    default: () => ({})
  },
  isBeltType: {
    type: Boolean,
    default: false
  },
  isBeltTypeBrand: {
    type: Boolean,
    default: false
  },
  isQuickAddBelt: {
    type: Boolean,
    default: false
  },
  complianceMode: {
    type: Boolean,
    default: false
  },
  complianceModeDe: {
    type: Boolean,
    default: false
  },
  isSpecialFlash: {
    type: Boolean,
    default: false
  },
  hasLink: {
    type: Boolean,
    default: false
  },
  isQuickAdd: {
    type: Boolean,
    default: false
  },
})
const emit = defineEmits(['click-link'])
const handleClick = () => {
  emit('click-link')
}

const complianceModeText = computed(() => {
  if (props.price.addOnAmountWithSymbol) {
    return props.texts.ifTotalOrderOver
  } else {
    return props.language.SHEIN_KEY_PC_30579 || 'Price after coupons and promotions' 
  }
})

const strategyStyle = computed(() => {
  let { config, isBeltType, isQuickAddBelt, isBeltTypeBrand, isSpecialFlash } = props
  let { normal, darkRed, vipNormalFlash, normalFlash, normalVip } = ESTIMATED_TAG_OLD_STYLE
  if (isQuickAddBelt) {
    if(isBeltTypeBrand) return darkRed
    return normalFlash
  }
  if (isBeltTypeBrand) return darkRed
  if (isBeltType) {
    if (isSpecialFlash) return darkRed
    if (config.isPaidUser) return vipNormalFlash
    return darkRed
  }
  if (isSpecialFlash) return darkRed
  if (config.isPaidUser) return normalVip
  return normal
})


const buyMultipleText = computed(() => {
  let left = template(props.price.amountWithSymbol, props.language.SHEIN_KEY_PWA_31767 || '{0}/Pc')
  let right = template(
    `<span style="font-weight: 700">${template(props.price.buyMultiplePic, props.language.SHEIN_KEY_PC_31525 || '{0}Pic')}</span>`,
    props.language.SHEIN_KEY_PC_31524 || 'With {0} purchase'
  )
  return {
    left,
    right
  }
})

</script>

<style lang="less">
.product-price-estimated {
  display: flex;
  align-items: center;
  min-height: 27px;
  padding: 5px 10px;
  border-radius: 2px;
  overflow: hidden;
  &__price {
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    & > span {
      vertical-align: middle;
    }
  }
  &__icon-link {
    margin-left: 3px;
  }
  &__vertical {
    width: 1px;
    height: 16px;
    opacity: .8;
    margin: 0 4px;
    background: #FA6338;
  }
  &__font-18px {
    font-size: 18px;
    font-weight: 700;
  }
  &__font-14px {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.price-area{
      flex: 0 0 auto;
      font-weight: 700;
    }
  }
  &__countdown-wrap{
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: 12px;
  }
  &__countdown-time{
    display: flex;
    align-items: center;
    margin-left: 2px;
    span {
      width: 18px;
      height: 18px;
      line-height: 18px;
      color: #FA6338;
      background-color: #fff;
      text-align: center;
      margin: 0 2px;
      border-radius: 2px;
    }
  }
  &__text-overflow2 {
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    white-space: normal;
  }
}
</style>

.
<template>
  <div
    :class="[
      'quick-flash',
      { 'quick-flash_brand': isBrandFlash }
    ]"
  >
    <div class="quick-flash__top">
      <div class="quick-flash__left">
        <p
          class="quick-flash__title"
        >
          <Icon
            v-if="!isBrandFlash"
            name="sui_icon_flashsale_12px"
            size="15px"
          />
          <svg
            v-if="isBrandFlash"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.33333 2.08325C6.49238 2.08325 5 3.57564 5 5.41659V8.30548H3.78185C3.42859 8.30548 3.23561 8.7175 3.46176 8.98889L7.05892 13.3055L10.6561 8.98889C10.8822 8.7175 10.6893 8.30548 10.336 8.30548H9.16667V4.16658C9.16667 3.01599 10.0994 2.08325 11.25 2.08325H8.33333Z"
              fill="#FA6338"
            />
          </svg>
          <span :style="{color: isBrandFlash ? '#FA6338' : ''}">{{ flashDescText }} </span>
        </p>
      </div>
      <div class="quick-flash__right">
        <div
          :class="[
            'quick-flash__headRight',
            { 'quick-flash__headRight_brand': isBrandFlash }
          ]"
        >
          <template v-if="priceBannerTypeNormalFlashData.showCountTime">
            <span class="quick-flash__ends-text">{{ globalTempalte('', language.SHEIN_KEY_PC_17111) }}</span>
            <span
              v-if="GB_cssRight"
              class="quick-flash__headRightHundred"
            >{{ priceBannerTypeNormalFlashData.secondHundred }}</span>
            <span>{{ Number(priceBannerTypeNormalFlashData.countdownStr.D) > 0 ? (priceBannerTypeNormalFlashData.countdownStr.D + 'd') : '' }} {{ priceBannerTypeNormalFlashData.countdownStr.H }}h : {{ priceBannerTypeNormalFlashData.countdownStr.M }}m : {{ priceBannerTypeNormalFlashData.countdownStr.S }}s</span>
            <span
              v-if="!GB_cssRight"
              class="quick-flash__headRightHundred"
            >{{ priceBannerTypeNormalFlashData.secondHundred }}</span>
          </template>
        </div>
      </div>
    </div>
    <div 
      class="quick-flash__bottom" 
      :class="{'quick-flash__estimated_bigSmall': isSheinappwordsizeNew}"
    >
      <div 
        v-if="!isPercentNewStyle"
        class="quick-flash__price" 
        v-html="mainPriceWordSizeFormater(salePrice)"
      ></div>
      <template v-if="estimatedCompProps && estimatedCompProps.config">
        <template v-if="complianceModeDe">
          <div
            v-if="deDiscountInfo.retailPrice.show"
            class="quick-flash__de-discount"
          >
            <span>{{ language.SHEIN_KEY_PC_31021 }}&ensp;</span>
            <del class="quick-flash__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
            <p
              v-if="deDiscountInfo.retailDiscountPercent.show"
              class="quick-flash__discount-percent"
            >
              {{ `${lang != 'il' ? '-' : ''}${deDiscountInfo.retailDiscountPercent.value}%` }}
            </p>
          </div>
          <div
            v-if="deDiscountInfo.suggestedSalePrice.show"
            class="quick-flash__de-discount"
            :style="{
              width: deDiscountInfo.retailPrice.show ? '100%' : '',
              margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
            }"
          >
            <span>{{ language.SHEIN_KEY_PC_31022 }}&ensp;</span>
            <del class="quick-flash__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
            <p
              v-if="deDiscountInfo.suggestedDiscountPercent.show"
              class="quick-flash__discount-percent"
            >
              {{ `${lang != 'il' ? '-' : ''}${deDiscountInfo.suggestedDiscountPercent.value}%` }}
            </p>
          </div>
        </template>
        <span v-else>
          <del
            v-if="suggestedSalePrice.show"
            class="quick-flash__retail"
            :style="{
              transform: isSheinappwordsizeNew ? 'translateY(-3px)' : '',
            }"
          >
            {{ suggestedSalePrice.value }}
          </del>
          <span
            v-if="!!suggestedSalePrice.tip"
            class="quick-flash__suggestedSalePrice-tip"
          >
            {{ suggestedSalePrice.tip }}
          </span>
        </span>
        
        <div class="quick-flash__flash-estimated">
          <EstimatedTag
            :is-belt-type="true"
            :is-quick-add-belt="true"
            :is-belt-type-brand="isBrandFlash"
            :class="{'quick-flash__estimated_bigSmall': isSheinappwordsizeNew}"
            :analysis-data="{ location: 'popup' }"
            :language="language"
            :estimated-info="estimatedInfo"
            :promotion-info="promotionInfo"
            :goods-id="goodsId"
            :has-link="estimatedTaghasLink"
            @click-link="clickEstimatedTag"
          />
        </div>
      </template>
      <template v-else>
        <template v-if="complianceModeDe">
          <div
            v-if="deDiscountInfo.retailPrice.show"
            class="quick-flash__de-discount"
          >
            <span>{{ language.SHEIN_KEY_PC_31021 }}&ensp;</span>
            <del class="quick-flash__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
            <p
              v-if="deDiscountInfo.retailDiscountPercent.show"
              class="quick-flash__discount-percent"
            >
              {{ `${lang != 'il' ? '-' : ''}${deDiscountInfo.retailDiscountPercent.value}%` }}
            </p>
          </div>
          <div
            v-if="deDiscountInfo.suggestedSalePrice.show"
            class="quick-flash__de-discount"
            :style="{
              width: deDiscountInfo.retailPrice.show ? '100%' : '',
              margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
            }"
          >
            <span>{{ language.SHEIN_KEY_PC_31022 }}&ensp;</span>
            <del class="quick-flash__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
            <p
              v-if="deDiscountInfo.suggestedDiscountPercent.show"
              class="quick-flash__discount-percent"
            >
              {{ `${lang != 'il' ? '-' : ''}${deDiscountInfo.suggestedDiscountPercent.value}%` }}
            </p>
          </div>
        </template>
        <template v-else-if="(retailPrice || suggestedSalePrice.show) && !hasEstimated">
          <del
            v-if="suggestedSalePrice.show && !suggestedSalePrice.tip"
            class="quick-flash__retail"
            :style="{
              transform: isSheinappwordsizeNew ? 'translateY(-3px)' : '',
            }"
          >
            {{ suggestedSalePrice.value }}
          </del>
          <del
            v-else-if="showSpecialFlashDelPrice"
            class="quick-flash__retail"
            :style="{
              transform: isSheinappwordsizeNew ? 'translateY(-3px)' : '',
            }"
          >
            {{ retailPrice }}
          </del>
          <div
            v-if="discountPercent && showPopDiscounTable"
            class="quick-flash__discount-percent"
            :style="{
              transform: isSheinappwordsizeNew ? 'translateY(-3px)' : '',
            }"
          >
            {{ `${lang != 'il' ? '-' : ''}${ discountPercent }%` }}
          </div>
          <span>
            <del
              v-if="suggestedSalePrice.show && !!suggestedSalePrice.tip"
              class="quick-flash__retail"
              :style="{
                transform: isSheinappwordsizeNew ? 'translateY(-3px)' : '',
              }"
            >
              {{ suggestedSalePrice.value }}
            </del>
            <span
              v-if="!!suggestedSalePrice.tip"
              class="quick-flash__suggestedSalePrice-tip"
            >
              {{ suggestedSalePrice.tip }}
            </span>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import EstimatedTag from 'public/src/pages/goods_detail_v2/components/EstimatedTag/index.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/salePriceWordSize'
import schttp from 'public/src/services/schttp'
import { template, CountDown } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
const { lang, GB_cssRight } = gbCommonInfo
export default {
  name: 'QuickFlashSale',
  components: {
    Icon,
    EstimatedTag
  },
  props: {
    goodsId: {
      type: String,
      default: ''
    },
    isSheinappwordsizeNew: {
      type: Boolean,
      default: false
    },
    estimatedCompProps: {
      type: Object,
      default: () => {}
    },
    estimatedInfo: {
      type: Object,
      default: () => {}
    },
    showEstimatedPrice: {
      type: Boolean,
      default: false
    },
    flashGoods: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    promotionInfo: {
      type: Object,
      default: () => {}
    },
    cccFlashZoneData: {
      type: Object,
      default: () => {}
    },
    isFlashSaleCountDownAbtHide: {
      type: Boolean,
      default: false
    },
    showPopDiscounTable: {
      type: Boolean,
      default: false
    },
    showSpecialFlashDelPrice: {
      type: Boolean,
      default: false
    },
    isPercentTypeStyleWrap: {
      type: Boolean,
      default: false
    },
    showDiscountWithEstimatedNoThresholdNew: {
      type: Boolean,
      default: false
    },
    retailPrice: {
      type: String,
      default: ''
    },
    discountPercent: {
      type: Number,
      default: 0
    },
    currentData: {
      type: Object,
      default: () => {}
    },
    salePrice: {
      type: String,
      default: ''
    },
    flashDescText: {
      type: String,
      default: ''
    },
    isPercentNewStyle: {
      type: Boolean,
      default: false
    },
    suggestedSalePrice: {
      type: Object,
      default: () => {}
    },
    complianceModeDe: {
      type: Boolean,
      default: false
    },
    estimatedTaghasLink: {
      type: Boolean,
      default: false
    },
    deDiscountInfo: {
      type: Object,
      default: () => null
    },
  },
  data() {
    return {
      GB_cssRight,
      lang,
      IS_RW: typeof window !== 'undefined' ? gbCommonInfo.IS_RW : false,
      is_include_critical_css:
        typeof window !== 'undefined'
          ? gbCommonInfo.is_include_critical_css
          : true,
      flashTimer: null,
      secondInterval: null,
      countdownStr: '',
      seconds: '00',
      priceBannerTypeNormalFlashData: {
        showCountTime: false,
        flashTimer: null,
        secondInterval: null,
        countdownStr: {
          D: '',
          H: '',
          M: '',
          S: ''
        },
        secondHundred: 9,
      }
    }
  },
  computed: {
    hasEstimated() {
      return this.estimatedCompProps && this.estimatedCompProps.config
    },
    isBrandFlash() {
      return this.flashGoods?.flashType == 6
    }
  },
  mounted() {
    this.initPriceBannerNormalFlashCountDown()
    this.isBrandFlash && daEventCenter.triggerNotice({
      daId: '1-8-6-200',
      extraData: {
        location: 'popup'
      }
    })
  },
  methods: {
    globalTempalte: template,
    async initPriceBannerNormalFlashCountDown() {
      if (this.isFlashSaleCountDownAbtHide) return
      const { currTime: serverTime } = await schttp({ url: '/api/productInfo/currentTime/get' })
      if (this.flashGoods?.endTimestamp && this.flashGoods?.endTimestamp < serverTime ) return
      this.priceBannerTypeNormalFlashData.flashTimer = new CountDown({
        countFunc: (timeObj) => {
          const { D } = timeObj || {}
          // 倒计时小于24小时 = 1 天 时展示倒计时文案
          if (Number(D) < 1 && this.priceBannerTypeNormalFlashData.showCountTime === false) {
            this.priceBannerTypeNormalFlashData.showCountTime = true
            // 额外百毫秒展示
            this.priceBannerTypeNormalFlashData.secondInterval = setInterval(() => {
              this.priceBannerTypeNormalFlashData.secondHundred --
              if (this.priceBannerTypeNormalFlashData.secondHundred < 0) this.priceBannerTypeNormalFlashData.secondHundred = 9
            }, 100)
          }
          if (this.priceBannerTypeNormalFlashData.showCountTime) {
            Object.assign(this.priceBannerTypeNormalFlashData.countdownStr, timeObj)
          }
        },
        endFunc: () => {
          this.priceBannerTypeNormalFlashData.showCountTime = false
          this.priceBannerTypeNormalFlashData.showFlashBelt = false
          this.priceBannerTypeNormalFlashData.flashTimer && this.priceBannerTypeNormalFlashData.flashTimer?.clear()
          this.priceBannerTypeNormalFlashData.secondInterval && clearInterval(this.priceBannerTypeNormalFlashData.secondInterval)
        }
      })
      this.priceBannerTypeNormalFlashData.flashTimer.start({
        dayMode: true,
        seconds: this.flashGoods?.endTimestamp - serverTime, // 单位是秒
      })
    },
    clickEstimatedTag() {
      this.$emits('handleClickEstimatedTag')
    },
    mainPriceWordSizeFormater(priceStr) {
      return salePriceWordSize({
        priceStr,
        size: 24,
        gate: this.isSheinappwordsizeNew
      })
    }
  },

}
</script>

<style lang="less">
.quick-flash {
  padding: 10px 12px 10px 12px;
  border-radius: 4px;
  border: 0.5px solid #FFF3C2;
  background: linear-gradient(180deg, #FFF5C7 0%, #FFF8E0 48.27%, #FFFCF0 100%);
  width: 100%;

  &_brand {
    background: linear-gradient(180deg, #FFEDE3 0%, #FFF8F5 100%);
    border: 0.5px solid #FFEBE3;
  }

  &__top{
    display: flex;
    justify-content: space-between;

  }
  &__title{
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }
  &__headRight {
    position: relative;
    z-index: @zindex-out;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    display: flex;
    align-items: center;
    .quick-flash__headRightHundred {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      font-weight: 700;
      background: rgba(250, 207, 25, 0.60);
      min-width: 16px;
      height: 16px;
      border-radius: 2px;
      margin-left: 4px /* rtl: ignore */;
    }
  }

  &__headRight_brand {
    color: #FA6338;
    .quick-flash__headRightHundred {
      color: #FFF;
      background: #FA6338;
    }
  }
  &__ends-text{
    margin-right: 4px;
  }
  &__headRightHundred {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: 700;
    background: rgba(250, 207, 25, 0.60);
    min-width: 16px;
    height: 16px;
    border-radius: 2px;
    margin-left: 4px /* rtl: ignore */;
  }

  &__headRightIcon {
    margin-right: 8px;
  }
  &__bottom{
    margin-top: 6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__estimated_bigSmall{
    align-items: flex-end;
  }
  &__flash-estimated{
    .product-price-estimated{
      color: var(---sui_color_white, #FFF);
    }
  }
  &__price{
    color: var(---sui_color_discount, #FA6338);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    font-family: Arial Black;
    margin-right: 4px;
  }
  &__retail{
    margin-right: 4px;
    color: rgba(102, 102, 102, 0.80);
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    text-decoration-line: strikethrough;
  }
  &__suggestedSalePrice-tip {
    font-family: SF Pro;
    font-size: 12px;
    font-weight: 400;
    color: #959595;
    margin-right: 4px;
  }
  &__discount-percent {
    padding: 2px;
    margin-right: 4px;
    border-radius: 2px; 
    border: 1px solid #FA6338;
    color: #FA6338;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__estimated_bigSmall {
    margin: 6px 0 0;
  }
  &__de-discount {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
  }
  &__de-discountDel {
    margin-right: 4px;
  }
}

</style>
